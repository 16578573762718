@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

@media screen and (max-width: 667px) {
  body {
      overflow-x: hidden !important;
  }
}
