@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,700&display=swap');

.backgroundColor {
  position: sticky !important;
  top: 0px;
  z-index: 999999;
  background: rgba(69, 4, 175, 0);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 0px 0px 10px 10px;
  height: 70px;
}

.navLink {
  display: flex;
}

.header-text-color {
  margin-top: 1em;
  color: #fc9e4f;
  text-decoration: none;
  font-size: 0.8em;
  font-family: Roboto Mono;
}

.header-text-color:hover {
  color: #e200ff;
}

.logo {
  width: 55px;
}

.resume {
  border: 1px;
  border-style: solid;
  border-color: #e200ff;
  padding: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border-radius: 12px;
  color: #fc9e4f;
  text-decoration: none;
  margin-top: 0.5em;
  font-size: 0.8em;
  font-family: Roboto Mono;
}

.resume:hover {
  border-color: #5edbf2;
  color: #e200ff;
}

@media (max-width: 767px) {
  .backgroundColor {
    background-color: #0a192f;
    position: sticky !important;
    top: 0px;
    z-index: 99;
    padding: 1em;
  }

  .navLink {
    display: flex;
  }

  .header-text-color {
    margin-top: 1em;
    color: #fc9e4f;
    text-decoration: none;
    font-size: 0.8em;
    font-family: Roboto Mono;
  }

  .logo {
    width: 45px;
  }

  .resume {
    border: 1px;
    border-style: solid;
    border-color: #e200ff;
    padding: 0.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    color: #fc9e4f;
    text-decoration: none;
    margin-top: 0.5em;
    font-size: 0.8em;
    font-family: Roboto Mono;
  }
}
