@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,700&display=swap');

.experience-container {
  background-color: #0a192f;
  width: 100vw;
  height: 100%;
  z-index: 999px;
}

.experience-text-container {
  padding: 10em;
}

.experience-text-header {
  color: #fc9e4f;
  font-family: Roboto Mono;
  font-size: 40px;
}

.experience-card:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(35, 69, 71) 0px 30px 60px -30px;
}

.circle_svg {
  position: absolute;
  z-index: 999;
}

.hero-circle {
  position: relative;
  bottom: 400px;
  width: 1000px;
  right: 700px;
  overflow-y: inherit;
}

.hero-icons {
  color: white;
}

#circle-large {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
}

#circle-small {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;

}

#circle-small2 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;

}

#circle-small3 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;
}

.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 1px solid #fc9e4f;
  color: white;
}

.floating2 {
  animation-name: floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 1px solid #fc9e4f;
  color: white;
}

.floating3 {
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 1px solid #fc9e4f;
  color: white;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 55px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@media (max-width: 767px) {
  .experience-container {
    background-color: #0a192f;
    width: 100vw;
    height: 100%;
  }

  .experience-text-container {
    padding: 1em;
  }

  .experience-text-header {
    color: #fc9e4f;
    font-family: Roboto Mono;
    font-size: 40px;
  }
}
