.projects-container {
  background-color: #0a192f;
  width: 100vw;
  height: 100%;
  z-index: 999px;
}

.project-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
}


.project-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  margin: 10px;
}

.projects-text-container {
  padding: 10em;
  z-index: 999px;
  color: white;
}

.projects-text-header {
  color: #fc9e4f;
  font-family: Roboto Mono;
  font-size: 40px;
  z-index: 999999999999999px;
}

.project-card {
  width: 50;
  height: 20rem;
  margin: 0em;
  background: rgba(62, 55, 55, 0.269);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.18);
}

#circle-large {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
}

#project-circle-small {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;
}

#project-circle-small2 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;
}

#project-circle-small3 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
  background-color: #0a192f;
}

.project-floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 50px solid #0500ff;
  z-index: -999px;
  filter: blur(15px);
  opacity: 50%;
}

.project-floating2 {
  animation-name: floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 80px solid #0500ff;
  z-index: -999px;
  filter: blur(15px);
  opacity: 50%;
}

.project-floating3 {
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 100px solid #0500ff;
  z-index: -999px;
  filter: blur(15px);
  opacity: 50%;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 65px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.card-image {
  display: block;
  width: 100%;
  height: 0.3vh;
  object-fit: cover;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.card-image-bottom {
  transform: rotateX(180deg);
  display: block;
  width: 100%;
  height: 0.5vh;
  object-fit: cover;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.card-bg {
  background-color: #2a5ca7;
}

@media (max-width: 767px) {
  .projects-container {
    background-color: #0a192f;
    width: 100vw;
    height: 100%;
  }

  .projects-text-container {
    padding: 1em;
  }

  .projects-text-header {
    color: #fc9e4f;
    font-family: Roboto Mono;
    font-size: 40px;
  }

  .project-button {
    display: flex;
    justify-content: space-between;
  }
  .project-card {
    margin: auto;
    width: 20rem;
    height: 20rem;
  }
}
