@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;900&display=swap');

/* //////////////////////////////////////////// */
.hero-container {
  background-color: #0a192f;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 99;
}

.video-modal {
  background: rgba(255, 4, 4, 0);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(26.5px);
  -webkit-backdrop-filter: blur(26.5px);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.18);
}

.video-bg{
  background: rgba(255, 255, 255, 0);
  
}

.text-container {
  padding: 7em;
}

.text {
  color: #fc9e4f;
  font-family: Roboto Mono;
  font-size: 20px;
}

.text-pronunciation {
  color: #fc9e4f;
  font-family: Roboto Mono;
  font-size: 15px;
}

#hero-circle-small {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
}

#hero-circle-small1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
}

#hero-circle-small2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  z-index: -999px;
}

.text-name {
  color: white;
  font-family: Poppins;
  font-size: 110px;
  font-weight: 900;
}

.text-desc {
  color: white;
  font-family: Poppins;
  font-size: 55px;
  font-weight: 900;
}

.text-summary {
  color: white;
  font-family: Poppins;
  font-size: 35px;
  margin-top: 1em;
}

@keyframes hero-floating {
  0% {
    transform: translate(0, 0px);
    transform: rotate(0deg);
  }
  50% {
    transform: translate(0, 105px);
  }
  100% {
    transform: translate(10, -0px);
    transform: rotate(360deg);
  }
}

.hero-float {
  animation-name: hero-floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 220px solid #ff7171;
  z-index: -999px;
  filter: blur(65px);
  opacity: 100%;
  width: 30px;
}

.hero-float2 {
  animation-name: hero-floating;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  border: 120px solid #c42eff;
  z-index: -999px;
  filter: blur(95px);
  opacity: 100%;
}

.hero-float3 {
  animation-name: hero-floating;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 220px solid #016698;
  z-index: -999px;
  filter: blur(65px);
  opacity: 100%;
  width: 30px;
}

.blob_2 {
  width: 1200px;
  height: 500px;
  border-radius: 100%;

  background: #0500ff;
  filter: blur(120px);
  opacity: 25%;
}

@media (max-width: 767px) {
  .hero-container {
    background-color: #0a192f;
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .text-container {
    padding: 1em;
    padding-top: 5em;
  }

  .text {
    color: #fc9e4f;
    font-family: Roboto Mono;
    font-size: 20px;
  }

  .text-pronunciation {
    color: #fc9e4f;
    font-family: Roboto Mono;
    font-size: 12px;
  }

  .text-name {
    color: white;
    font-family: Poppins;
    font-size: 70px;
    font-weight: 900;
  }

  .text-desc {
    color: white;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 900;
  }

  .text-summary {
    color: white;
    font-family: Poppins;
    font-size: 25px;
    margin-top: 1em;
  }

  .circle_svg {
    position: absolute;
    z-index: 999;
  }

  .hero-circle {
    position: relative;
    bottom: 400px;
    width: 900px;
    overflow-y: inherit;
    z-index: -1;
  }

  .hero-icons {
    color: white;
  }
}
