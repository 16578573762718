.g-recaptcha {
  display: inline-block;
  margin-bottom: 20px;
}

.g-recaptcha-response {
  margin-top: 10px;
}

.contact-container{
  width: 100%;
}

.contact-form {
  max-width: 80vw;
  margin: 0 auto;
}

.contact-form h2 {
  color: #FFA500;
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-control {
  width: 100%;
  padding: 10px;
  border: none;
  background: rgba(1, 7, 120, 0.233);
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.contact-form .form-control:focus {
  background: rgba(255, 255, 255, 0.2);
}

.contact-form .btn-primary {
  display: inline-block;
  padding: 24px;
  border: 0;
  text-decoration: none;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
  color: rgba(255,255,255,0.8);
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
}

.contact-form .btn-primary:hover {
  background-color: rgb(0, 51, 255);
}


