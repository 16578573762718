@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,700&display=swap');

.about-container {
  background-color: #0a192f;
  overflow-x: hidden;
  z-index: 999px;
}

.about-text-container {
  padding: 10em;
  z-index: 999px;
}

.about-text-header {
  color: #fc9e4f;
  font-family: Roboto Mono;
  font-size: 40px;
}

hr {
  width: 120px;
}

.about-text-desc {
  color: white;
  font-family: Poppins;
  font-size: 25px;
}

.about-list > li {
  color: white;
  font-size: 20px;
  list-style-type: none;
}

.about-list-title {
  font-family: Roboto Mono;
  color: #fc9e4f;
  margin-left: 0px;
  margin-bottom: 10px;
  padding-top: 15px;
}

@media (max-width: 767px) {
  .about-container {
    background-color: #0a192f;
    overflow-x: hidden;
    z-index: 999px;
  }

  .about-text-container {
    padding: 0.5em;
  }

  .about-text-header {
    color: #fc9e4f;
    font-family: Roboto Mono;
    font-size: 40px;
  }

  hr {
    width: 120px;
  }

  .about-text-desc {
    color: white;
    font-family: Poppins;
    font-size: 20px;
  }

  .about-list > li {
    color: white;
    font-size: 20px;
    list-style-type: none;
  }

  .about-list-title {
    font-family: Roboto Mono;
    color: #fc9e4f;
    margin-left: -10px;
  }
}
